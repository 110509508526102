import React, { useState, useEffect } from "react";
import {
  BaseButtons,
  TextInputField,
} from "../../../../../../components/atoms";

const InventoryManageCard = ({
  productName,
  NameAssemble,
  selectedProduct,
  product,
  sku,
  supplier,
  image,
  isStore,
  processSale,
  onAddClick,
  priceDisplayed,
  price,
  index,
  productsArray,
}) => {
  const [areSure, setAreSure] = useState(false);
  const [info, setInfo] = useState(null);
  const [stockValue, setStockValue] = useState(0);
  const GetWareHouseStockInfo = async () => {
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/inventory/getLineItemWarehouseStockLevel",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            productId: product._id,
          }),
        }
      );

      const responseData = await response.json();
      // console.log("response", responseData);
      if (responseData.success) {
        setInfo(responseData.stockAmount);
        setStockValue(responseData.stockAmount.currentStock);
        // setMessage(responseData.success);
        // setToastPop(true);
      }
    } catch (err) {
      console.log("error", err);
    }
  };

  const GetStoreStockInfo = async () => {
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/inventory/storeStockFetchValue",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            productId: product._id,
          }),
        }
      );

      const responseData = await response.json();
      // console.log("response", responseData);
      if (responseData.success) {
        setInfo(responseData.stockAmount);
        setStockValue(responseData.stockAmount.currentStock);
        // setMessage(responseData.success);
        // setToastPop(true);
      }
    } catch (err) {
      console.log("error", err);
    }
  };

  useEffect(() => {
    if (isStore) {
      GetStoreStockInfo();
    } else {
      GetWareHouseStockInfo();
    }
  }, [productsArray]);
  return (
    <div
      style={{
        height: 70,
        width: "100%",
        borderBottomStyle: "solid",
        borderBottomWidth: 1,
        borderBottomColor: "#00000030",
        display: "flex",
      }}
    >
      <div
        style={{
          flex: 0.1,

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
          overflow: "hidden",
        }}
      >
        <img alt="product" src={image} style={{ height: 50 }} />
      </div>
      <div
        style={{
          flex: 0.25,

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
        }}
      >
        <div style={{ marginLeft: 15, width: "100%", textAlign: "left" }}>
          {productName}
        </div>
      </div>

      <div
        style={{
          flex: 0.2,

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
        }}
      >
        <div style={{ marginLeft: 35, width: "100%", textAlign: "left" }}>
          {sku}
        </div>
      </div>
      {processSale ? null : (
        <div
          style={{
            flex: 0.1,

            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 11,
          }}
        >
          <div style={{ marginLeft: 25, width: "100%", textAlign: "left" }}>
            {supplier}
          </div>
        </div>
      )}
      <div
        style={{
          flex: priceDisplayed ? 0.15 : 0.3,

          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
          alignItems: "center",
          fontSize: 11,
        }}
      >
        {/* <div
            style={{
              fontSize: 14,
              marginTop: 15,
              marginRight: 5,
            }}
          >
            UNIT
          </div> */}
        <div>
          {priceDisplayed ? (
            product ? (
              product.type === "composite" ? null : (
                <div
                  style={{
                    display: "flex",

                    flexDirection: "column",

                    padding: 5,
                    width: 50,
                    borderRadius: 4,
                    alignItems: "flex-start",
                  }}
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      fontSize: 10,
                      opacity: 0.6,
                      width: "90%",
                      textAlign: "center",
                    }}
                  >
                    QTY
                  </div>
                  <div
                    style={{
                      marginTop: 8,
                      fontSize: 14,
                      fontWeight: "bold",
                      width: "90%",
                      // borderStyle: "solid",
                      // padding: 3,
                      borderRadius: 4,
                      textAlign: "center",
                      marginBottom: 3,
                    }}
                  >
                    {" "}
                    {stockValue}
                  </div>
                </div>
              )
            ) : (
              <div
                style={{
                  display: "flex",

                  flexDirection: "column",

                  padding: 5,
                  width: 50,
                  borderRadius: 4,
                  alignItems: "flex-start",
                }}
              >
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: 10,
                    opacity: 0.6,
                    width: "90%",
                    textAlign: "center",
                  }}
                >
                  QTY
                </div>
                <div
                  style={{
                    marginTop: 8,
                    fontSize: 14,
                    fontWeight: "bold",
                    width: "90%",
                    // borderStyle: "solid",
                    // padding: 3,
                    borderRadius: 4,
                    textAlign: "center",
                    marginBottom: 3,
                  }}
                >
                  {" "}
                  {stockValue}
                </div>
              </div>
            )
          ) : (
            <TextInputField
              invert={true}
              mini={true}
              height={10}
              marginTop={-5}
              size={processSale ? 40 : 200}
              labelTitle={"QTY"}
              type={"Number"}
              readOnly={
                processSale || isStore ? true : stockValue ? false : true
              }
              value={
                processSale || isStore
                  ? info
                    ? info.currentStock
                    : stockValue
                  : info
                  ? stockValue
                    ? stockValue
                    : info.currentStock
                  : stockValue
              }
              onChange={
                processSale || isStore
                  ? (e) => setStockValue(0)
                  : (e) => setStockValue(e.target.value)
              }
            />
          )}
        </div>
      </div>
      {priceDisplayed ? (
        <div
          style={{
            flex: 0.2,

            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 14,
          }}
        >
          <div
            style={{
              fontSize: 10,
              fontWeight: "bold",
              opacity: 0.6,
              marginBottom: 8,
            }}
          >
            PRICE
          </div>
          <div
            style={{
              fontSize: 14,
              fontWeight: "bold",
              // opacity: 0.6,
              marginBottom: 5,
            }}
          >
            {price ? "R " + price : null}
          </div>
        </div>
      ) : null}
      {processSale ? (
        <div
          style={{
            flex: 0.1,

            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 11,
          }}
        >
          <BaseButtons
            label={"ADD"}
            mini={true}
            marginTopFalse={true}
            borderRadius={8}
            height={35}
            size={80}
            onClick={onAddClick}
            marginRight={10}
            disable={
              product.type === "composite"
                ? false
                : stockValue >= 1
                ? false
                : true
            }
          />
        </div>
      ) : (
        <div
          style={{
            flex: 0.15,

            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 11,
          }}
        >
          {isStore ? (
            <BaseButtons
              label={"VERIFY"}
              mini={true}
              marginTopFalse={true}
              borderRadius={8}
              height={35}
              size={130}
              disable={true}
            />
          ) : (
            <BaseButtons
              label={"SAVE"}
              mini={true}
              marginTopFalse={true}
              borderRadius={8}
              height={35}
              size={100}
              disable={
                info ? (info.currentStock === stockValue ? true : false) : true
              }
            />
          )}
        </div>
      )}
    </div>
  );
};

export default InventoryManageCard;
