import React, { useState, useContext } from "react";
import { AuthContext } from "../../../../../../context/auth-context";
import { BaseButtons, CheckInput } from "../../../../../../components/atoms";
import { LoadingPopUp } from "../../../../../../components";

const Consent = ({ setSelectedSection, setConsent }) => {
  const [answer5, setAnswer5] = useState("");

  const dateCheck = new Date().toDateString();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("no data");
  const auth = useContext(AuthContext);

  const consentForm = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/medicalHistory/createAnswerSheet",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // Authorization: `bearer ${token}`,
          },
          body: JSON.stringify({
            id: auth.customerUserData.idDoc,
            section: "consent",
            answer1: "date:" + dateCheck,
            answer2:
              "patient full name:" +
              " " +
              auth.customerUserData.firstName +
              " " +
              auth.customerUserData.lastName,
            answer3:
              "paient date of birth:" + " " + auth.customerUserData.dateOfBirth,
            answer4:
              "patient address:" +
              " " +
              auth.customerUserData.street +
              ", " +
              auth.customerUserData.suburb +
              ", " +
              auth.customerUserData.town,
            answer5: "signedConsent" + " " + answer5,
            answer6: "signed on date:" + dateCheck,
            answer7: "doctor: Dr. Chuma Makunga",
            answer8: "no data",
            answer9: "no data",
            answer10: "no data",
            answer11: "no data",
            answer12: "no data",
          }),
        }
      );

      const respnseData = await response.json();
      if (respnseData.data) {
        setLoading(false);
        setSelectedSection("none");
        setConsent(true);
      }
    } catch (err) {
      setErrorMessage("Unable to upload answer sheet");
      setTimeout(() => {
        setLoading(false);
      }, 3000);
      console.log("error", err);
    }
  };

  return (
    <div style={{ height: "100%", width: "100%", overflow: "scroll" }}>
      {loading ? (
        <LoadingPopUp display={loading} />
      ) : errorMessage !== "no data" ? (
        <div
          onClick={() => setErrorMessage("no data")}
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {errorMessage}
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <img
            alt="logo"
            src={require("../../../../../../images/newLogos/1. MJ Logo Black with White Surround.png")}
            style={{ height: 120, marginTop: 20 }}
          />
          <div style={{ fontSize: 12 }}>
            Natural Healer Medical Practitioner
          </div>
          <div style={{ fontSize: 12 }}>Reg No: MP0524123</div>
          <div style={{ fontSize: 12 }}>Practice No: 0074004</div>
          <div
            style={{
              marginTop: 20,
              width: "80%",
              textAlign: "left",
              fontSize: 14,
              fontWeight: "bold",
            }}
          >
            PATIENT CONSENT FOR TREATMENT
          </div>
          <div
            style={{
              marginTop: 10,
              width: "80%",
              textAlign: "left",
              fontSize: 12,
              fontWeight: "bold",
            }}
          >
            DATE:
          </div>
          <div
            style={{
              marginTop: 10,
              width: "80%",
              textAlign: "left",
              fontSize: 14,
              marginLeft: 10,
            }}
          >
            {dateCheck}
          </div>
          <div
            style={{
              marginTop: 10,
              width: "80%",
              textAlign: "left",
              fontSize: 12,
              fontWeight: "bold",
            }}
          >
            PATIENT FULLNAME:
          </div>
          <div
            style={{
              marginTop: 10,
              width: "80%",
              textAlign: "left",
              fontSize: 14,
              marginLeft: 10,
            }}
          >
            {auth.customerUserData.firstName +
              " " +
              auth.customerUserData.lastName}
          </div>
          <div
            style={{
              marginTop: 10,
              width: "80%",
              textAlign: "left",
              fontSize: 12,
              fontWeight: "bold",
            }}
          >
            PATIENT DATE OF BIRTH:
          </div>
          <div
            style={{
              marginTop: 10,
              width: "80%",
              textAlign: "left",
              fontSize: 14,
              marginLeft: 10,
            }}
          >
            {auth.customerUserData.dateOfBirth}
          </div>
          <div
            style={{
              marginTop: 10,
              width: "80%",
              textAlign: "left",
              fontSize: 12,
              fontWeight: "bold",
            }}
          >
            PATIENT ADDRESS:
          </div>
          <div
            style={{
              marginTop: 10,
              width: "80%",
              textAlign: "left",
              fontSize: 14,
              marginLeft: 10,
            }}
          >
            {auth.customerUserData.street +
              ", " +
              auth.customerUserData.suburb +
              ", " +
              auth.customerUserData.town}
          </div>
          <div
            style={{
              marginTop: 10,
              width: "80%",
              textAlign: "left",
              fontSize: 14,
              fontWeight: "bold",
            }}
          >
            Acknowledgement
          </div>
          <div
            style={{
              marginTop: 10,
              width: "80%",
              textAlign: "left",
              fontSize: 14,
            }}
          >
            I hereby accept and grant consent for complimentary cannabis
            treatments, with the goal of promoting alternative health and
            well-being through Dr. Chuma Makunga a Registered Healthcare
            practitioner appointed and acting on behalf of Merry-Jane Holdings
            Pty Ltd and or any other member of the Traditional Healthcare
            Practitioners in service through Merry-Jane Holdings. I accept the
            responsibility to read and understand the treatment description,
            available alternatives, and other beneficial information. I
            acknowledge that this consent remains valid for the prescribed
            treatment duration.
          </div>
          <div
            style={{
              marginTop: 10,
              width: "80%",
              textAlign: "left",
              fontSize: 14,
              fontWeight: "bold",
            }}
          >
            Description of Treatment:
          </div>
          <div
            style={{
              marginTop: 10,
              width: "80%",
              textAlign: "left",
              fontSize: 14,
            }}
          >
            The prescribed treatment(s) is designed to elicit a positive
            response to my health and well-being, emphasizing the ongoing
            identification of effective approaches for my conditions or relief
            through the use of cannabinoids as an alternative health care
            method.
          </div>
          <div
            style={{
              marginTop: 10,
              width: "80%",
              textAlign: "left",
              fontSize: 14,
              fontWeight: "bold",
            }}
          >
            Risks and Benefits:
          </div>
          <div
            style={{
              marginTop: 10,
              width: "80%",
              textAlign: "left",
              fontSize: 14,
            }}
          >
            Individuals may experience initial effects upon the commencement of
            cannabis use until a tolerance is established. This period is
            characterized by physiological adjustments, and the intensity of
            effects may diminish over time as the body adapts to the application
            of cannabinoids as an alternative relief where after positive
            effects may be achieved.
          </div>
          <div
            style={{
              marginTop: 10,
              width: "80%",
              textAlign: "left",
              fontSize: 14,
            }}
          >
            Cannabis consumption is to occur in a private and secure
            environment, dosages must be closely monitored, and adjustments must
            be made to minimize adverse effects.
          </div>
          <div
            style={{
              marginTop: 10,
              width: "80%",
              textAlign: "left",
              fontSize: 14,
              fontWeight: "bold",
            }}
          >
            Alternatives:
          </div>
          <div
            style={{
              marginTop: 10,
              width: "80%",
              textAlign: "left",
              fontSize: 14,
            }}
          >
            Exploring a diverse range of cannabis strains is encouraged, given
            the vast array of cannabinoids and their unique combinations.
            Prescribed products may include options for inhalation, ingestion,
            topical application, or rectal administration, allowing for a
            personalized approach that aligns with patient preferences.
          </div>
          <div
            style={{
              marginTop: 10,
              width: "80%",
              textAlign: "left",
              fontSize: 14,
            }}
          >
            It is crucial to comprehend the effects and initial reactions to
            cannabis use, as this understanding is essential for evaluating and
            tailoring additional treatment options based on specific medical
            conditions.
          </div>
          <div
            style={{
              marginTop: 10,
              width: "80%",
              textAlign: "left",
              fontSize: 14,
              fontWeight: "bold",
            }}
          >
            Questions and Concerns:
          </div>
          <div
            style={{
              marginTop: 10,
              width: "80%",
              textAlign: "left",
              fontSize: 14,
            }}
          >
            Our alternative health team wholeheartedly welcomes direct
            communication to address any personal concerns related to the
            prescribed full cannabinoid spectrum treatment(s). Your comfort and
            understanding are of utmost importance, and we encourage open
            dialogue to ensure that your individual needs and questions are
            addressed effectively.
          </div>
          <div
            style={{
              marginTop: 10,
              width: "80%",
              textAlign: "left",
              fontSize: 14,
              fontWeight: "bold",
            }}
          >
            Acknowledgment:
          </div>
          <div
            style={{
              marginTop: 10,
              width: "80%",
              textAlign: "left",
              fontSize: 14,
            }}
          >
            I confirm that I have had the opportunity to engage in discussions
            with Dr. Chuma Makunga or any other designated member of the
            Healthcare Team regarding the proposed treatment. These discussions
            encompassed a thorough exploration of the associated risks,
            benefits, and alternative options. I am aware that I retain the
            right to withdraw my consent at any point. Additionally, I have been
            provided with the opportunity to schedule an appointment with the
            specialized Healthcare Team for personalized treatment related to
            previously diagnosed medical conditions as an alternative option.
          </div>
          <div
            style={{
              marginTop: 10,
              width: "80%",
              textAlign: "left",
              fontSize: 14,
              fontWeight: "bold",
            }}
          >
            Voluntary Consent:
          </div>
          <div
            style={{
              marginTop: 10,
              width: "80%",
              textAlign: "left",
              fontSize: 14,
            }}
          >
            By accepting these terms and conditions I,
            {(
              " " +
              auth.customerUserData.firstName +
              " " +
              auth.customerUserData.lastName
            ).toUpperCase()}
            , voluntarily consent to the proposed treatment and prescription,
            recognizing that no guarantees have been made regarding the outcomes
            of the treatment. I acknowledge that it is my responsibility to
            consume cannabis in a private space.
          </div>
          <div style={{ height: 20 }} />
          <CheckInput
            invert={true}
            question={
              "by clicking here you agree to and have signed the consent form"
            }
            check={answer5.length >= 5 ? true : false}
            onClick={() =>
              setAnswer5(
                answer5.length >= 5
                  ? " "
                  : "I" +
                      " " +
                      auth.customerUserData.firstName +
                      " " +
                      auth.customerUserData.lastName +
                      " " +
                      "consent to treatment"
              )
            }
          />
          <div
            style={{
              marginTop: 10,
              width: "80%",
              textAlign: "left",
              fontSize: 14,
              marginLeft: 10,
            }}
          >
            Signed on date : {dateCheck}
          </div>

          <div
            style={{
              width: "80%",
              borderStyle: "solid",
              marginTop: 70,
              position: "relative",
            }}
          >
            <img
              alt="signiture"
              src={require("./Dr_Makunga__e-signature.png")}
              style={{ height: 80, position: "absolute", top: -60, left: 20 }}
            />
            <div
              style={{ height: 50, position: "absolute", top: -30, right: 20 }}
            >
              Dr. Chuma Makunga
            </div>
          </div>
          <div
            style={{
              marginTop: 10,
              width: "80%",
              textAlign: "left",
              fontSize: 14,
              marginLeft: 10,
            }}
          >
            Signed on date : {dateCheck}
          </div>
          <div style={{ marginTop: 20 }}>
            <BaseButtons
              label={"SUBMIT"}
              onClick={() => consentForm()}
              disable={answer5.length >= 5 ? false : true}
            />
          </div>
        </div>
      )}
      <div style={{ height: 80 }} />
    </div>
  );
};

export default Consent;
