import React, { useState, useEffect } from "react";
import { BlackAndWhite } from "../../../../../../../../themes/Colors";
import {
  BaseButtons,
  CloseButton,
  PinTextInputField,
} from "../../../../../../../../components/atoms";

const ProcessRefundOrExhange = ({
  setCompletePopUp,
  user,
  setLoading,
  setMessage,
  setToastPop,
  exchangeRefundArray,
  display,
  selectedTransactionDisplay,
  totalIncluding,
  CalculateTotal,
  title,
  storeInfo,
  setCloseUp,
  setSelectedTransactionDisplay,
  setViewTransactionDisplay,
}) => {
  const [pin1, setPin1] = useState("");
  const [selectedBudtender, setselectedBudtender] = useState(null);
  const [staffArray, setStaffArray] = useState(null);

  const [refundOptionDisplay, serRefundOptionDisplay] = useState("CASH");

  const [userVerfiied, setUserVerified] = useState(false);

  const GetStoreUsers = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/processSale/getAllStoreUsers",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            storeName: user.storeName,
          }),
        }
      );

      const responseData = await response.json();

      if (responseData.success) {
        setStaffArray(responseData.storeUsers);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.log("error", err);
    }
  };
  const ConfirmAccess = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/processSale/checkUserAccessPin",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            storeName: user.storeName,
            userId: user.userId,
            pin: pin1,
          }),
        }
      );

      const responseData = await response.json();

      if (responseData.success) {
        setUserVerified(true);
        setLoading(false);
      } else {
        setLoading(false);
        setMessage(responseData.message);
        setToastPop(true);
      }
    } catch (err) {
      setLoading(false);
      setMessage("No network try again later");
      setToastPop(true);
      console.log("error", err);
    }
  };
  const CompleteRefundExchange = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/processSale/completExchangeRefund",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: user.userId,
            storeId: storeInfo.storeId,
            storeName: user.storeName,

            // cashier rates
            name: user.firstName + " " + user.lastName,
            slipNo: selectedTransactionDisplay.slipNo,
            Address:
              storeInfo.unitNumber +
              " , " +
              storeInfo.streetAddress +
              " , " +
              storeInfo.suburb +
              " , " +
              storeInfo.city +
              " , " +
              storeInfo.province +
              " , " +
              storeInfo.postalCode,
            contactNumber: storeInfo.landLine,
            //customer info
            customerName: selectedTransactionDisplay.customerName,
            customerUserId: selectedTransactionDisplay.customerUserId,
            //products array
            productsArray: exchangeRefundArray,
            //payment info
            total: totalIncluding,
            comments: ["empty"],
            type: title,
            refundOption: refundOptionDisplay,
          }),
        }
      );

      const responseData = await response.json();

      if (responseData.success) {
        setLoading(false);
        setMessage(responseData.success);
        setToastPop(true);
        setCompletePopUp(false);
        setUserVerified(false);
        setselectedBudtender(null);
        setPin1("");
        setCloseUp(false);
        setSelectedTransactionDisplay(null);
        setViewTransactionDisplay(false);
      } else {
        setLoading(false);
        setMessage(responseData.message);
        setToastPop(true);
      }
    } catch (err) {
      setLoading(false);
      console.log("error", err);
    }
  };

  useEffect(() => {
    if (pin1.length === 4) {
      ConfirmAccess();
    }
  }, [pin1]);
  useEffect(() => {
    CalculateTotal();
    GetStoreUsers();
    //get all store users
  }, []);
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        height: display ? "100%" : 0,
        width: "100%",
        backgroundColor: "#00000070",
        overflow: "hidden",
        left: 0,
        zIndex: 30000000000,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          height: userVerfiied ? 450 : 700,
          width: 600,
          backgroundColor: BlackAndWhite.secondary,
          borderRadius: 10,
          display: "flex",
          flexDirection: "column",
          position: "relative",
          alignItems: "center",
        }}
      >
        <div style={{ position: "absolute", top: 20, left: 20 }}>
          <CloseButton
            label={"X"}
            onClick={() => {
              setCompletePopUp(false);
              setUserVerified(false);
              setselectedBudtender(null);
              setPin1("");
            }}
          />
        </div>
        {userVerfiied ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              alignItems: "center",
              width: "100%",
            }}
          >
            <div style={{ fontSize: 24, fontWeight: "bold", marginTop: 25 }}>
              {" "}
              COMPLETE {title}
            </div>
            <div
              style={{
                width: "80%",
                fontSize: 16,
                marginTop: 20,
                // marginBottom: 20,
              }}
            >
              AMOUNT TO {title}
            </div>
            <div
              style={{
                width: "80%",
                fontSize: 50,
                fontWeight: "bold",
                marginTop: 20,
                marginBottom: 20,
              }}
            >
              R {totalIncluding.toFixed(2)}
            </div>
            {title === "EXCHANGE" ? (
              <div
                style={{
                  width: "90%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div style={{ width: "90%" }}>
                  THIS AMOUNT WILL BE ADDED AS STORE CREDIT TO USER{" "}
                </div>
                <div
                  style={{
                    width: "90%",
                    fontSize: 30,
                    marginTop: 20,
                    marginBottom: 20,
                  }}
                >
                  {selectedTransactionDisplay
                    ? selectedTransactionDisplay.customerName
                    : "loading"}
                </div>
                <BaseButtons
                  label={"ASSIGN STORE CREDIT"}
                  size={"90%"}
                  mini={true}
                  borderRadius={8}
                  onClick={() => CompleteRefundExchange()}
                />
              </div>
            ) : (
              <div
                style={{
                  width: "90%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div style={{ width: "90%", marginTop: 10 }}>
                  DOES THE CUSTOMER WANT ?{" "}
                </div>
                <div
                  style={{
                    width: "90%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                  }}
                >
                  <BaseButtons
                    label={"CASH"}
                    mini={true}
                    borderRadius={8}
                    marginRight={20}
                    onClick={() => serRefundOptionDisplay("CASH")}
                    invert={refundOptionDisplay === "CASH" ? false : true}
                  />
                  <BaseButtons
                    label={"CREDIT"}
                    mini={true}
                    borderRadius={8}
                    onClick={() => serRefundOptionDisplay("CREDIT")}
                    invert={refundOptionDisplay === "CREDIT" ? false : true}
                  />
                </div>
                <BaseButtons
                  label={"COMPLETE REFUND TRANSACTION"}
                  mini={true}
                  borderRadius={8}
                  onClick={() => CompleteRefundExchange()}
                  size={"80%"}
                />
              </div>
            )}
          </div>
        ) : (
          <div
            style={{
              height: 700,
              width: 500,

              borderRadius: 10,
              display: "flex",
              flexDirection: "column",
              position: "relative",
              overflow: "hidden",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "90%",
                marginTop: 20,
                marginBottom: 10,
                fontSize: 18,
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              SELECT USER AND ENTER YOUR PIN
            </div>
            {selectedBudtender ? null : (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  minHeight: 300,
                  maxHeight: 700,
                  flex: 1,
                  flexDirection: "column",
                }}
              >
                {staffArray
                  ? staffArray.map((user, index) => {
                      return (
                        <div
                          onClick={() => setselectedBudtender(user)}
                          key={index}
                          style={{
                            width: "99%",
                            height: 70,

                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer",
                            marginBottom: 10,
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "95%",
                              width: "90%",
                              backgroundColor: BlackAndWhite.secondary,
                              WebkitBoxShadow: "0px 0px 7px 2px #00000050",
                              borderRadius: 8,
                            }}
                          >
                            {user.firstName + " " + user.lastName}
                          </div>
                        </div>
                      );
                    })
                  : "Loading"}
              </div>
            )}

            {selectedBudtender ? (
              <>
                <div
                  onClick={() => {
                    setselectedBudtender(null);
                    setPin1("");
                  }}
                  style={{
                    height: 100,
                    width: "100%",

                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      width: "85%",
                      borderStyle: "solid",
                      height: 90,
                      borderRadius: 8,
                      display: "flex",
                      alignItems: "center",
                      overflow: "hidden",
                    }}
                  >
                    <div
                      style={{
                        height: "100%",
                        width: 100,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        alt="image"
                        src={require("../../../../../../../../images/material-icons/profileBlack.png")}
                        style={{ height: 70 }}
                      />
                    </div>
                    {selectedBudtender
                      ? selectedBudtender.firstName +
                        " " +
                        selectedBudtender.lastName
                      : "loading"}
                  </div>
                </div>

                <div style={{ width: "85%", fontSize: 14, marginTop: 20 }}>
                  INPUT PIN
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    marginBottom: 20,
                  }}
                >
                  <div style={{ marginLeft: 20 }} />
                  <PinTextInputField
                    value={pin1}
                    readOnly={pin1.length >= 4 ? true : false}
                    onChange={(e) => setPin1(e.target.value)}
                    placeholder={"0000"}
                    autoFocus={true}
                  />
                  {/* <TextInputField
                  mini={true}
                  size={30}
                  invert={true}
                  width={80}
                  height={60}
                  type={"number"}
                  fontsize={24}
                  value={pin1}
                  onChange={(e) => OnchangeReturnPin1({ e })}
                  placeholder={"0"}
                  autoFocus={pin1Focus}
                /> */}
                  {/* <TextInputField
                  mini={true}
                  size={30}
                  invert={true}
                  width={80}
                  height={60}
                  type={"number"}
                  fontsize={24}
                  value={pin2}
                  onChange={(e) => setPin2(e.target.value)}
                  placeholder={"0"}
                />
                <TextInputField
                  mini={true}
                  size={30}
                  invert={true}
                  width={80}
                  height={60}
                  type={"number"}
                  fontsize={24}
                  value={pin3}
                  onChange={(e) => setPin3(e.target.value)}
                  placeholder={"0"}
                />
                <TextInputField
                  mini={true}
                  size={30}
                  invert={true}
                  width={80}
                  height={60}
                  type={"number"}
                  fontsize={24}
                  value={pin4}
                  onChange={(e) => setPin4(e.target.value)}
                  placeholder={"0"}
                  autoFocus={pin4Focus}
                /> */}
                </div>

                <div
                  style={{
                    flex: 1,
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      flex: 0.25,

                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <BaseButtons
                      label={"1"}
                      fontSize={25}
                      mini={true}
                      marginTopFalse={true}
                      size={90}
                      height={70}
                      borderRadius={8}
                      onClick={() => setPin1(pin1 + "1")}
                      marginRight={10}
                    />
                    <BaseButtons
                      label={"2"}
                      fontSize={25}
                      mini={true}
                      marginTopFalse={true}
                      size={90}
                      height={70}
                      borderRadius={8}
                      onClick={() => setPin1(pin1 + "2")}
                      marginRight={10}
                    />
                    <BaseButtons
                      label={"3"}
                      fontSize={25}
                      mini={true}
                      marginTopFalse={true}
                      size={90}
                      height={70}
                      borderRadius={8}
                      onClick={() => setPin1(pin1 + "3")}
                    />
                  </div>
                  <div
                    style={{
                      flex: 0.25,
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <BaseButtons
                      label={"4"}
                      fontSize={25}
                      mini={true}
                      marginTopFalse={true}
                      size={90}
                      height={70}
                      borderRadius={8}
                      onClick={() => setPin1(pin1 + "4")}
                      marginRight={10}
                    />
                    <BaseButtons
                      label={"5"}
                      fontSize={25}
                      mini={true}
                      marginTopFalse={true}
                      size={90}
                      height={70}
                      borderRadius={8}
                      onClick={() => setPin1(pin1 + "5")}
                      marginRight={10}
                    />
                    <BaseButtons
                      label={"6"}
                      fontSize={25}
                      mini={true}
                      marginTopFalse={true}
                      size={90}
                      height={70}
                      borderRadius={8}
                      onClick={() => setPin1(pin1 + "6")}
                    />
                  </div>
                  <div
                    style={{
                      flex: 0.25,
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <BaseButtons
                      label={"7"}
                      fontSize={25}
                      mini={true}
                      marginTopFalse={true}
                      size={90}
                      height={70}
                      borderRadius={8}
                      onClick={() => setPin1(pin1 + "7")}
                      marginRight={10}
                    />
                    <BaseButtons
                      label={"8"}
                      fontSize={25}
                      mini={true}
                      marginTopFalse={true}
                      size={90}
                      height={70}
                      borderRadius={8}
                      onClick={() => setPin1(pin1 + "8")}
                      marginRight={10}
                    />
                    <BaseButtons
                      label={"9"}
                      fontSize={25}
                      mini={true}
                      marginTopFalse={true}
                      size={90}
                      height={70}
                      borderRadius={8}
                      onClick={() => setPin1(pin1 + "9")}
                    />
                  </div>
                  <div
                    style={{
                      flex: 0.25,

                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <BaseButtons
                      label={"0"}
                      fontSize={25}
                      mini={true}
                      marginTopFalse={true}
                      size={200}
                      height={70}
                      borderRadius={8}
                      onClick={() => setPin1(pin1 + "0")}
                      marginRight={14}
                    />
                    <BaseButtons
                      label={"REMOVE"}
                      fontSize={12}
                      mini={true}
                      marginTopFalse={true}
                      size={77}
                      height={70}
                      borderRadius={8}
                      onClick={() =>
                        setPin1(pin1.substring(0, pin1.length - 1))
                      }
                    />
                  </div>
                </div>
              </>
            ) : null}

            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 20,
                marginBottom: 15,
              }}
            >
              <BaseButtons
                label={"CANCEL"}
                mini={true}
                marginTopFalse={true}
                // marginRight={selectedBudtender ? 20 : 0}
                borderRadius={8}
                size={"90%"}
                onClick={() => {
                  // setCompleteSale(false);
                  setselectedBudtender(null);
                  setPin1("");
                }}
              />
              {/* {selectedBudtender ? (
              <BaseButtons
                label={"CONFIRM"}
                mini={true}
                marginTopFalse={true}
                borderRadius={8}
                size={"45%"}
                onClick={() => setUserVerified("true")}
              />
            ) : null} */}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProcessRefundOrExhange;
