import React, { useState } from "react";
import { BlackAndWhite } from "../../../../themes/Colors";

const CannaMenuButton = ({
  iconImage,
  width,
  height,
  onClick,
  selected,
  title,
  fontColor,
  buttonHeight,
  disable,
}) => {
  const [hover, setHover] = useState(false);
  return (
    <div
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      onClick={disable ? null : onClick}
      style={{
        height: buttonHeight ? buttonHeight + 10 : 55,
        width: width,
        borderRadius: 8,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        cursor: "pointer",
        fontSize: 30,
        fontWeight: "bold",
        transition: "all 0.3s",
        opacity: disable ? 0.5 : 1,
      }}
    >
      <div
        style={{
          transition: "all 0.3s",
          minHeight: buttonHeight ? buttonHeight : 40,
          maxHeight: buttonHeight ? buttonHeight : 40,
          minWidth: width ? width : 50,
          maxWidth: width ? width : 50,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 8,
          backgroundColor: selected
            ? BlackAndWhite.primary
            : BlackAndWhite.secondary,
          borderStyle: "solid",
          borderWidth: selected ? 0 : hover ? 3 : 0,
          borderColor: BlackAndWhite.primary,
        }}
      >
        {title ? (
          <div
            style={{
              fontSize: 16,
              fontWeight: "bold",
              color: fontColor,
            }}
          >
            {title}
          </div>
        ) : iconImage ? (
          <img alt="icon" src={iconImage} style={{ height: height }} />
        ) : (
          "?"
        )}
      </div>
    </div>
  );
};

export default CannaMenuButton;
