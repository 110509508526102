import React, { useState, useEffect } from "react";
import { BlackAndWhite } from "../../../../../../../../themes/Colors";
import {
  BaseButtons,
  CloseButton,
  TextInputField,
} from "../../../../../../../../components/atoms";

const ProductDisplayItem = ({
  NameAssemble,
  product,
  isRefund,
  onRemoveClick,
  EditQty,
  index,
}) => {
  const [amount, setAmount] = useState("");
  useEffect(() => {
    // ReturnArrayRecieptArray();
    setAmount(Number(product.qtyCard.qty));
  }, []);
  return (
    <div
      style={{
        width: "90%",
        backgroundColor: BlackAndWhite.secondary,
        height: 80,
        borderRadius: 10,
        WebkitBoxShadow: "0px 0px 7px 2px #00000050",
        display: "flex",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: 80,
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img alt="icon" style={{ height: 70 }} src={product.product.imageUrl} />
      </div>
      <div>{NameAssemble({ product: product.product })}</div>
      <div style={{ flex: 1 }} />
      <div
        style={{
          marginLeft: 20,
          marginRight: 30,
          fontWeight: "bold",
        }}
      >
        <TextInputField
          invert={true}
          labelTitle={"QTY"}
          mini={true}
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          type={"number"}
          height={10}
          size={80}
        />
      </div>

      <div
        style={{
          marginRight: 20,
          fontWeight: "bold",
          fontSize: 18,
        }}
      >
        R {product.product.retailPriceInc}
      </div>
      {isRefund ? (
        <div style={{ marginRight: 15 }}>
          {product.qtyCard.qty === amount ? null : (
            <BaseButtons
              mini={true}
              label={"SAVE"}
              onClick={() => EditQty({ idIndex: index, qty: amount })}
              borderRadius={8}
              marginTop={-1}
              size={100}
              height={50}
              fontSize={20}
            />
          )}
        </div>
      ) : null}
      {isRefund ? (
        <div style={{ marginRight: 15 }}>
          <BaseButtons
            mini={true}
            label={"X"}
            onClick={onRemoveClick}
            borderRadius={8}
            marginTop={-1}
            size={50}
            height={50}
            fontSize={20}
          />
        </div>
      ) : null}
    </div>
  );
};
export default ProductDisplayItem;
