import React, { useState } from "react";
import {
  BaseButtons,
  CheckInput,
  TextInputField,
} from "../../../../../../../../components/atoms";
import { BlackAndWhite } from "../../../../../../../../themes/Colors";

const ProductDisplaySelect = ({
  product,
  NameAssemble,
  index,
  onAddClick,
  AddNewItemToArray,
  UpDateInfoHandler,
}) => {
  const [qtyAmount, setQtyAmount] = useState("");
  const [fullUnit, setFullUnit] = useState(false);

  return (
    <div
      style={{
        width: "95%",
        minHeight: 80,
        borderRadius: 8,
        backgroundColor: BlackAndWhite.secondary,
        WebkitBoxShadow: "0px 0px 7px 2px #00000050",
        marginBottom: 20,
        // marginTop: index === 0 ? 20 : 0,
        display: "flex",
        alignItems: "center",
      }}
    >
      <div>
        {" "}
        <img
          alt="icon"
          style={{ height: 60, marginLeft: 10 }}
          src={product.imageUrl}
        />
      </div>
      <div style={{ fontSize: 12, width: 200 }}>
        {" "}
        {NameAssemble({ product })}
      </div>
      <div
        style={{
          flex: 0.5,
          display: "flex",
          flexDirection: "column",
          height: 57,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            fontSize: 12,
            fontWeight: "bold",
            opacity: 0.7,
            marginBottom: 4,
          }}
        >
          MEASURE
        </div>
        <div
          style={{
            fontSize: 14,
            fontWeight: "bold",
            opacity: 0.7,
            marginBottom: 4,
          }}
        >
          {product.size + " " + product.quantityDenomiation}
        </div>
      </div>

      <div style={{ flex: 1, display: "flex" }}>
        <div style={{ flex: 1, marginBottom: 10 }}>
          <TextInputField
            labelTitle={`QTY`}
            mini={true}
            invert={true}
            height={10}
            size={60}
            type={"number"}
            placeholder={"0.00"}
            value={qtyAmount}
            onChange={(e) => setQtyAmount(e.target.value)}
          />
        </div>

        <div style={{ width: 150, marginRight: 10, marginTop: 5 }}>
          <CheckInput
            question={"FULL UNIT"}
            invert={true}
            onClick={() => setFullUnit(fullUnit ? false : true)}
            check={fullUnit}
          />
        </div>
      </div>
      <BaseButtons
        label={"ADD"}
        fontSize={18}
        size={65}
        height={35}
        mini={true}
        marginTopFalse={true}
        borderRadius={4}
        marginRight={20}
        onClick={() => {
          AddNewItemToArray({
            product,
            qtyCard: {
              qty: qtyAmount,
              productId: product._id,
              fullUnit: fullUnit,
            },
          });
          UpDateInfoHandler(product.baseCostPrice);
        }}
      />
    </div>
  );
};

export default ProductDisplaySelect;
